






import { Component, Vue, Prop } from 'vue-property-decorator';
import VueToastr from 'vue-toastr';
@Component({components: {
  VueToastr,
},
})
export default class Toaster extends Vue {
  @Prop() message!: string;
  @Prop() defaultTimeout!: number;

  private localMessage = '';
  private localDefaultTimeout = 0;

  private mounted() {
    // set props value in local var
    this.localMessage = this.message!;
    this.localDefaultTimeout = this.defaultTimeout!;
    // set styling of toaster message
    (this.$refs.mytoast as any).defaultPosition = 'toast-top-center';
    (this.$refs.mytoast as any).defaultProgressBar = false;
    (this.$refs.mytoast as any).defaultTimeout = this.localDefaultTimeout;
    (this.$refs.mytoast as any).defaultClassNames  = ['toastr-custom'];
    (this.$refs.mytoast as any).defaultStyle = { 'background-color': '#D4EDDA' ,'color': '#155724' ,'opacity': '1' };
    // Send message to browser screen
    const s = (this.$refs.mytoast as any).s;
    s(
      this.localMessage,
    );
  }
}
