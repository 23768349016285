



























import { Component, Vue } from 'vue-property-decorator';
import Tabs from '@/components/Common/Tabs.vue';
import { EventSettingTabList } from '@/entities/EventSetting/EventSettingTabList';
import { TabViewModel } from '@/entities/TabViewModel';
import VueModalPopUp from '@/components/Common/VueModalPopUp.vue';
import { VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import i18n from '@/i18n';
import DialoguesTab from '../DialoguesTab/DialoguesTab.vue';
import CustomizeMenuItemTab from '../CustomizationTab/CustomizeMenuItemTab.vue';
import Toaster from '@/components/Common/Toaster.vue';
import loading from '@/components/Common/loading.vue';

@Component({
  components: {
    Tabs,
    VueModalPopUp,
    Toaster,
    loading,
  },
})
export default class EventInformationTabs extends Vue {
  private tabViewModel: EventSettingTabList = new EventSettingTabList();
  private eventSettingTabEntityList: TabViewModel[] = this.tabViewModel.TabViewModels;
  private showVueModalPopUp = false;
  private unsavedModalPopUp: VueModalPopUpViewModel = VueModalPopUpViewModel.createEmpty();
  private oldTabValue = '';
  private newTabValue = '';
  private dummyVar = false;
  private inImageProcess = false;
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private showToaster = false;
  private showLoader = false;
  private toasterMessage = i18n.t('EventSetting.SuccessUpdateMessage').toString();
  private toasterDefaultTimeout = 5000;
  private loaderBorderColor = '';


  private mounted() {
    const appInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
    if (appInfo.EnableDialogueThemes) {
      // Push dialogue tab data in tab list if feature is enable from super admin
      this.eventSettingTabEntityList.push({ Id: 3, ComponentName: DialoguesTab, Header: i18n.t('EventSetting.Dialogues').toString(), IsDisabled: false });
    }
    if (appInfo.EnableCustomMenuNames) {
      // Push Customization tab data in tab list if feature is enable from super admin
      this.eventSettingTabEntityList.push({ Id: 4, ComponentName: CustomizeMenuItemTab, Header: i18n.t('EventSetting.Customization').toString(), IsDisabled: false });
    }
    this.unsavedModalPopUp = new VueModalPopUpViewModel(
      i18n.t('UnsavedChanges').toString(),
      i18n.t('EventSetting.UnsavedItems').toString(),
      true,
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('@/assets/Images/Icon-warning.svg'),
      i18n.t('CancelButton').toString(),
      i18n.t('SaveAndSwitch').toString(),
    );
    this.$root.$on('is-dirty', (val: any) => {
      this.dummyVar = val;
    });
    this.$root.$on('disableTabs', (val: any) => {
      this.eventSettingTabEntityList.forEach((e) => {
        e.IsDisabled = val;
      });
    });
    this.$root.$on('show-toaster', (val: any, message: string) => {
      this.showToaster = val;
      this.toasterMessage = (message === '' || message === undefined) ? i18n.t('EventSetting.SuccessUpdateMessage').toString() : message;
    });
    this.$root.$on('show-loader', (val: any) => {
      this.showLoader = val;
    });
    this.$root.$on('save-features', () => {
      // show save message when user press save button from unsaved changes modal popup
      this.toasterMessage = i18n.t('EventSetting.SuccessUpdateMessage').toString();
      this.showLoader = true;
      this.showToaster = true;
      setTimeout(() => {
        this.showLoader = false;
        this.showToaster = false;
      }, 3000);
    });
  }
  private selectedTab(tabIndex: any, newTab: any, oldTab: any) {
    if (this.dummyVar) {
      // emit value for enable or disable background whenever unsaved changes popUp are their
      this.$root.$emit('disable-background-event-setting', true);
      this.showVueModalPopUp = true;
      this.oldTabValue = oldTab.title;
      this.newTabValue = newTab.title;
    } else {
      // emit value for enable or disable background whenever unsaved changes popUp are their
      this.$root.$emit('disable-background-event-setting', false);
    }
  }
  private SaveModelPopUp() {
    // emit of save tab's data when user press save button from unsaved changes modal popup
    switch (this.oldTabValue) {
    case 'Features & Integrations':
      this.$root.$emit('save-features', false);
      break;
    case 'Event information':
      this.$root.$emit('save-event-setting', false);
      break;
    case 'Dialogues':
      this.$root.$emit('save-dialogue-themes', false);
      break;
    case 'Customization':
      this.$root.$emit('save-custom-menu-names', false);
      break;
    default:
      break;
    }

    this.showVueModalPopUp = false;
    // emit value for enable or disable background whenever unsaved changes popUp are their
    this.$root.$emit('disable-background-event-setting', false);
    this.dummyVar = false;
  }
  private CloseModelPopup() {
    this.$root.$emit('select-previous-tab',this.oldTabValue);
    setTimeout(() => {
      this.showVueModalPopUp = false;
      // emit value for enable or disable background whenever unsaved changes popUp are their
      this.$root.$emit('disable-background-event-setting', false);
    }, 50);
  }
}
