




















import { WarningMessageWithButtonViewModel } from '@/entities/WarningMessageWithButtonViewModel';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WarningMessageWithButton extends Vue {
  @Prop() private WarningMessageViweModel!: WarningMessageWithButtonViewModel;
  private localViewModel = new WarningMessageWithButtonViewModel();

  private mounted() {
    // if value is available then assign to local variable
    if (this.WarningMessageViweModel !== null && this.WarningMessageViweModel !== undefined) {
      this.localViewModel = this.WarningMessageViweModel;
    }
  }

  private convertEvent() {
    this.$emit('convert-event');
  }
}
