





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { VueTabs, VTab } from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';
import { TabViewModel } from '@/entities/TabViewModel';

// Component
@Component({
  components: {
    VueTabs,
    VTab,
  },
})
export default class Tabs extends Vue {
  @Prop() tabViewModel!: TabViewModel[];
  @Prop() selectedtab?: string;
  private localTabViewModel: TabViewModel[] = [];
  private SelectedTab = '';

  private mounted() {
    this.localTabViewModel = this.tabViewModel;
    if (this.selectedtab !== undefined && this.selectedtab !== null) {
      this.SelectedTab= this.selectedtab!;
    }
    this.$root.$on('select-previous-tab', (val: string) => {
      this.SelectedTab = val;
    });
  }
  private handleTabChange(tabIndex: any, newTab: any, oldTab: any){
    this.$emit('selectedTab',tabIndex, newTab, oldTab);
  }
}
