















































































































import { ApplicationInstanceModel } from '@/entities/EventSetting/ApplicationInstanceModel';
import i18n from '@/i18n';
import CustomMenuItemTranslations from '@/entities/EventSetting/CustomMenuItemTranslationsViewModel';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import LanguagesViewModel from '@/entities/EventSetting/LanguagesViewModel';
import CustomMenuDefaultTranslationsViewModel from '@/entities/EventSetting/CustomMenuDefaultTranslationsViewModel';
import Common from '@/helper/Common';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { StoreHelper } from '@/store/StoreHelper';
import CreateAccountType from '@/enums/CreateAccountType';
@Component({
  components: {
    ValidationSummary,
  },
})
export default class CustomizeMenuItemTab extends Vue {
  private localCustomMenuLanguages: LanguagesViewModel[] = [];
  private localDefaultTranslations: CustomMenuDefaultTranslationsViewModel[] = [];
  private localCustomMenuItemTranslations: any[] = [];
  private oldLocalCustomMenuItemTranslations: any[] = [];
  private defaultLanguageId = 1;
  private isDirtyObject = true;
  private isValidationSummaryVisible = false;
  private validationErrorFields: string[] = [];
  private validationSummaryHeading = this.$t('Warning').toString();
  private localEventDetail: ApplicationInstanceModel = ApplicationInstanceModel.createEmpty();
  private oldLocalEventDetail: ApplicationInstanceModel = ApplicationInstanceModel.createEmpty();
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
  private myInformationId = 10;
  private mounted() {
    this.$root.$on('custom-menu-dto',(languages: LanguagesViewModel[], customMenuDefaultTranslations: CustomMenuDefaultTranslationsViewModel[], eventDetails: ApplicationInstanceModel)=> {
      this.localCustomMenuLanguages = languages.sort((item1, item2) => item1.Language.toLowerCase() > item2.Language.toLowerCase() ? 1 : -1);
      this.localDefaultTranslations = customMenuDefaultTranslations;
      this.localEventDetail = Object.assign({},eventDetails);
      this.oldLocalEventDetail = Object.assign({},eventDetails);
      if(this.localEventDetail.NeedCustomMenuNames === true) {
        this.CustomizeMenuItemName();
        this.SetCustomMenuTranslatedData(this.localEventDetail.CustomMenuItemTranslations);
        this.oldLocalCustomMenuItemTranslations = JSON.parse(JSON.stringify(this.localCustomMenuItemTranslations));
      }
    });
    this.$root.$on('save-custom-menu-names',()=> {
      this.Submit();
    });
  }
  private SetCustomMenuTranslatedData(translations: CustomMenuItemTranslations[]) {
    if(translations!== null && translations!== undefined && this.localCustomMenuItemTranslations!== null && this.localCustomMenuItemTranslations!== undefined) {
      translations.forEach((item)=> {
        const localCustomMenuItem = this.localCustomMenuItemTranslations.find(
          (menuItem) => menuItem.CustomMenuItemKeyId === item.CustomMenuItemKeyId,
        );
        if (localCustomMenuItem) {
          localCustomMenuItem.Id = item.Id;
          localCustomMenuItem.Value = item.Value;
          localCustomMenuItem.IsCheckboxChecked = true;
        }
      });
    }
  }
  private CustomizeMenuItemName() {
    // Setting default values from localDefaultTranslations on the basis of language id
    if(this.localDefaultTranslations!== null && this.localDefaultTranslations!== undefined && this.localEventDetail!== null && this.localEventDetail!== undefined) {
      if(this.localEventDetail.LanguageId === 0 && this.localEventDetail.NeedCustomMenuNames) {
        this.localEventDetail.LanguageId = this.defaultLanguageId;
      }
      this.localCustomMenuItemTranslations = this.localDefaultTranslations.filter((item) => item.LanguageId === this.localEventDetail.LanguageId).map((item) => ({Id: 0,CustomMenuItemKeyId: item.CustomMenuItemKeyId, Value: '', IsCheckboxChecked: false}));
    }
  }
  private ToggleCustomMenuItemInputBox(val: CustomMenuItemTranslations) {
    val.Value = '';
  }
  private GetCustomMenuItemName(customMenuItemKeyId: number) {
    // On the basis CustomMenuItemKeyId getting the corresponding menu item name
    if(this.localDefaultTranslations !== null && this.localDefaultTranslations!== undefined && customMenuItemKeyId !== null && customMenuItemKeyId !== undefined) {
      const menuItem = this.localDefaultTranslations.find(
        (item) =>
          item.CustomMenuItemKeyId === customMenuItemKeyId &&
        item.LanguageId === this.localEventDetail.LanguageId,
      );
      return menuItem!.Value;
    }
  }
  private ValidateCustomMenuNameField(customMenuItem: any) {
    return customMenuItem.Value === '' && this.validationErrorFields.includes(i18n.t('EventSetting.Customization.MenuNameCanNotBeEmpty').toString());
  }
  private IsObjectDirty() {
    const objectState = (JSON.stringify(this.oldLocalCustomMenuItemTranslations) === JSON.stringify(this.localCustomMenuItemTranslations) && JSON.stringify(this.oldLocalEventDetail) === JSON.stringify(this.localEventDetail));
    this.IsTabDisable();
    if (!objectState) {
      this.IsDirtyFlag();
    }
  }
  private IsTabDisable(){
    this.$root.$emit('disableTabs', false);
    if (this.CheckIfAnyMenuItemIsEmpty() || this.CheckIfAtleastOneMenuItemIsOverridden()) {
      this.$root.$emit('disableTabs', true);
    }
  }
  private IsDirtyFlag() {
    this.isDirtyObject = false;
    this.$root.$emit('is-dirty', true);
  }
  private CloseValidationSummary(val: boolean) {
    this.isValidationSummaryVisible = val;
  }
  private Discard() {
    this.isDirtyObject = true;
    this.$root.$emit('is-dirty',false);
    this.validationErrorFields = [];
    this.isValidationSummaryVisible = false;
    this.localEventDetail = Object.assign({},this.oldLocalEventDetail);
    this.localCustomMenuItemTranslations = JSON.parse(JSON.stringify(this.oldLocalCustomMenuItemTranslations));
  }
  private ValidateData() {
    if(this.CheckIfAtleastOneMenuItemIsOverridden()) {
      this.validationErrorFields.push(i18n.t('EventSetting.Customization.OverrideAtleastOne').toString());
      return false;
    } else if(this.CheckIfAnyMenuItemIsEmpty()) {
      this.validationErrorFields.push(i18n.t('EventSetting.Customization.MenuNameCanNotBeEmpty').toString());
    } else {
      return true;
    }
  }
  private CheckIfAtleastOneMenuItemIsOverridden() {
    if(this.localCustomMenuItemTranslations !== null && this.localCustomMenuItemTranslations !== undefined) {
      return !this.localCustomMenuItemTranslations.some((item: any) => item.IsCheckboxChecked === true) && this.localEventDetail.NeedCustomMenuNames;
    }
  }
  private CheckIfAnyMenuItemIsEmpty() {
    if(this.localCustomMenuItemTranslations !== null && this.localCustomMenuItemTranslations !== undefined) {
      return this.localCustomMenuItemTranslations.some((item: any) => item.Value === '' && item.IsCheckboxChecked === true);
    }
  }
  private HideCustomMenuItem(customMenuItemKeyId: number) {
    return this.reqInfo.CreateAccountTypeId === CreateAccountType.AutoCreateUsernameAndPassword && customMenuItemKeyId === this.myInformationId;
  }
  private Submit() {
    this.validationErrorFields = [];
    this.isValidationSummaryVisible = false;
    Common.ScrollToTop('main-container');
    if(this.ValidateData()) {
      this.$root.$emit('show-loader', true);
      const customMenuObject = this.localEventDetail.NeedCustomMenuNames ? this.localCustomMenuItemTranslations.filter((item) => item.Value !== '').map((item) => ({Id: item.Id,CustomMenuItemKeyId: item.CustomMenuItemKeyId, Value: item.Value})) : [];
      this.localEventDetail.CustomMenuItemTranslations = customMenuObject;
      this.localEventDetail.LanguageId = this.localEventDetail.NeedCustomMenuNames ? this.localEventDetail.LanguageId : 0;
      const objForJson = {
        Latitude: this.localEventDetail.Latitude,
        Longitude: this.localEventDetail.Longitude,
      };
      this.$axios
        .post<ApplicationInstanceModel>(
        '/ApplicationInstance/SaveOrEditApplicationInstance',
        {
          applicationInstanceModel: this.localEventDetail,
          jsonData : JSON.stringify(objForJson),
        },
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((response: any) => {
          if (response.data.ApplicationInstance !== null && response.data.ApplicationInstance !== undefined) {
          // disable dirty flag
            this.$root.$emit('is-dirty',false);
            this.$root.$emit('event-detail-dto-feature');
            this.isDirtyObject = true;
          }
          this.oldLocalCustomMenuItemTranslations = JSON.parse(JSON.stringify(this.localCustomMenuItemTranslations));
          // reload application view modal for update left side menu bar
          this.$root.$emit('reload-GetApplicationViewModel');
          // show hide save message and loader
          setTimeout(() => {
            this.$root.$emit('show-loader', false);
            this.$root.$emit('show-toaster', true, i18n.t('EventSetting.SuccessUpdateMessage').toString());
            setTimeout(() => {
              this.$root.$emit('show-toaster', false);
            }, 2000);
          }, 1000);
        })
        .catch(() => {
          this.$root.$emit('show-loader', false);
        });
    } else {
      this.isValidationSummaryVisible = true;
    }
  }
  @Watch('localEventDetail',{deep: true})
  private CheckChangeInlocalEventDetail() {
    this.CustomizeMenuItemName();
    this.SetCustomMenuTranslatedData(this.localEventDetail.CustomMenuItemTranslations);
  }
}
