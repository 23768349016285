


























import { WarningMessageBarViewModel } from '@/entities/WarningMessageBarViewModel';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class WarningMessageBar extends Vue {
  @Prop() private InformationViewModel!: WarningMessageBarViewModel;
  private localViewModel: WarningMessageBarViewModel =
    WarningMessageBarViewModel.createEmpty();
  private CrossIcon: string = require('@/assets/Images/cross-icon.svg');
  private InformationIcon = require('@/assets/Images/info-icon.svg');
  private readonly Information = 'Information!';

  private mounted() {
    this.localViewModel = this.InformationViewModel;
    if (this.InformationViewModel !== null && this.InformationViewModel !== undefined && this.localViewModel.IconURL !== null && this.localViewModel.IconURL !== undefined) {
      this.InformationIcon = this.localViewModel.IconURL;
    }
  }
  private Close() {
    this.$emit('information-message-bar-close-operation');
  }
  @Watch('InformationViewModel')
  private CheckChangeInInformationViewModel(val: WarningMessageBarViewModel) {
    this.localViewModel = val;
    if(this.localViewModel.IconURL !== null && this.localViewModel.IconURL !== undefined) {
      this.InformationIcon = this.localViewModel.IconURL;
    }
  }
}
