import i18n from '@/i18n';
import EventDetail  from '@/components/EventSetting/EventDetail.vue';
import FeaturesAndIntegrations  from '@/components/EventSetting/FeaturesAndIntegrations/FeaturesAndIntegrations.vue';
import { TabViewModel } from '@/entities/TabViewModel';

export class EventSettingTabList {
  public TabViewModels: TabViewModel[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor() {
    this.TabViewModels.push({ Id: 1, ComponentName: EventDetail, Header: i18n.t('EventSetting.EventInformation').toString() ,IsDisabled: false});
    this.TabViewModels.push({ Id: 2, ComponentName: FeaturesAndIntegrations, Header: i18n.t('EventSetting.FeaturesAndIntegrations').toString(), IsDisabled: false });
  }
}
