


































































import DialogueCharts from './DialogueCharts/DialogueCharts.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DialogueThemeEntity } from '@/entities/DialogueTheme/DialogueThemeEntity';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import DialogueThemesEnum from '@/enums/DialogueThemesEnum';
import i18n from '@/i18n';
import DialogueThemesSettings from '@/helper/DialogueThemesSettings';
@Component({
  components: {
    DialogueCharts,
  },
})
export default class DialogueTheme extends Vue {
  // get props fom parent component
  @Prop() private DialogueTheme!: DialogueThemeEntity;
  @Prop() private AppliedThemeId!: number;
  @Prop() private SelectedThemeId!: number;

  // Local variables
  private localDialogueTheme: DialogueThemeEntity = DialogueThemeEntity.createEmpty();
  private isHoverBarVisible = false;
  private menuBarIcon = require('@/assets/Images/overflow-menu.svg');
  private isMenuVisible = false;
  private showCharts = false;
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private localSelectedThemeId = 0;
  private localAppliedThemeId = 0;
  private reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();

  private mounted() {
    // assign props in local vars
    this.localDialogueTheme = Object.assign({}, this.DialogueTheme);
    this.localAppliedThemeId = this.AppliedThemeId;
    this.localSelectedThemeId = this.SelectedThemeId;

    // Set default theme bassed on there ids
    if (this.localDialogueTheme.Id === DialogueThemesEnum.LightTheme) {
      this.localDialogueTheme.LogoImageUrl = this.reqInfo.LogoUriDarkMode;
    } else if (this.localDialogueTheme.Id === DialogueThemesEnum.DarkTheme) {
      this.localDialogueTheme.LogoImageUrl = this.reqInfo.LogoURI;
    } else if (this.localDialogueTheme.Id === DialogueThemesEnum.MultiColorTheme) {
      this.localDialogueTheme.LogoImageUrl = this.reqInfo.LogoUriDarkMode;
    }
    this.localDialogueTheme.BackgroundImageUrl = this.localDialogueTheme.BackgroundImageUrl === null ? '' : this.localDialogueTheme.BackgroundImageUrl;
    // Set data in localObject for charts
    this.localDialogueTheme.Chartdata = DialogueThemesSettings.PreviewChartsData;
    this.localDialogueTheme.ChartTitle =  i18n.t('Title').toString();
    this.showCharts = true;
    this.$root.$on('change-selected-theme', (num: number) => {
      this.localSelectedThemeId = num;
    });
  }
  private ShowCustomThemePanel() {
    this.$root.$emit('show-custom-theme-panel',true,JSON.parse(JSON.stringify(this.localDialogueTheme)));
  }
  private ConfirmDelete() {
    this.$root.$emit('confirm-delete',true,this.localDialogueTheme.Id,this.localDialogueTheme.Id === this.localAppliedThemeId ? true : false);
  }
  // show or hide hover bar
  private ShowHideHoverBar(val: boolean) {
    this.isHoverBarVisible = val;
  }

  // Show or hide menu bar
  private ShowMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }

  @Watch('AppliedThemeId')
  private ChangeAppliedThemeId(newVal: any ) {
    // set in local
    this.localAppliedThemeId= newVal;
  }

  @Watch('SelectedThemeId')
  private ChangeSelectedThemeId(newVal: any ) {
    // set in local
    this.localSelectedThemeId = newVal;
  }

}
