




















































































import WarningMessageBar from '@/components/Common/WarningMessageBar.vue';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { WarningMessageBarViewModel } from '@/entities/WarningMessageBarViewModel';
import i18n from '@/i18n';
import { StoreHelper } from '@/store/StoreHelper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    WarningMessageBar,
  },
})
export default class SelfCheckIn extends Vue {
  @Prop() private CheckedselfCheckInCheckBox!: boolean;
  @Prop() private eventAccessLinkQRCodeUrl!: string;

  private showWarningMessageBar = false;
  private warningMessage: WarningMessageBarViewModel = WarningMessageBarViewModel.createEmpty();
  private qrCodeIcon: string = require('@/assets/Images/QR_code_icon.svg');
  private isCheckedselfCheckInCheckBox = false;
  private selfCheckInInformationMessage: WarningMessageBarViewModel = WarningMessageBarViewModel.createEmpty();
  private localEventAccessLinkQRCodeUrl = '';
  private tutorialLink = 'https://support.ventla.io/in-admin-tutorials/g5lc4iik0pUZjRg2sQFv/self-check-in';
  private contactUsToEnableLink = 'https://support.ventla.io/in-admin-tutorials/g5lc4iik0pUZjRg2sQFv/contact-us-to-enable';
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();

  private created() {
    this.selfCheckInInformationMessage = new WarningMessageBarViewModel(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('@/assets/Images/info-icon.svg'),
      i18n.t('EventSetting.SelfCheckInInformationMessage').toString(),
      i18n.t('EventSetting.EventInformation').toString(),
      true,
    );
    this.isCheckedselfCheckInCheckBox = this.CheckedselfCheckInCheckBox;
    this.localEventAccessLinkQRCodeUrl = this.eventAccessLinkQRCodeUrl;
  }

  private mounted(){
    // assign props to local var
    this.isCheckedselfCheckInCheckBox = this.CheckedselfCheckInCheckBox;
    this.localEventAccessLinkQRCodeUrl = this.eventAccessLinkQRCodeUrl;
    this.$root.$on('hide-selfcheckin-warning-bar',()=>{
      this.showWarningMessageBar = false;
    });
    this.$root.$on('reload-eventsettingtab', () => {
      this.reqInfo = this.storeHelper.GetRequestInfoFromStore();
    });
  }

  private WarningMessageBarCloseOperation() {
    // hide warning message bar
    this.showWarningMessageBar = false;
  }
  private changeSelfCheckInCheckBox() {
    if (this.reqInfo.IsParticipantSelfCheckInActive) {
      // change self checkin checkbox value and show warning message bar
      this.isCheckedselfCheckInCheckBox = !this.isCheckedselfCheckInCheckBox;
      this.showWarningMessageBar = true;
      // emit value to parent component
      this.$emit('changevaluecheckin', this.isCheckedselfCheckInCheckBox);
    }
  }
  private OpenImageInPopup() {
    // open QR image / download QR image when QR code link is available
    if(this.localEventAccessLinkQRCodeUrl !== '' && this.localEventAccessLinkQRCodeUrl !== undefined) {
      this.$emit('openimageinppup');
    }
  }
  private GetQRCodeToolTipMessage(){
    // Get QRCode ToolTip Message
    return  this.isCheckedselfCheckInCheckBox ? this.$t('EventSetting.ToolTip.SelfCheckInQRCodeEnable') : this.$t('EventSetting.ToolTip.SelfCheckInQRCodeDisable');
  }

  @Watch('CheckedselfCheckInCheckBox')
  private onCheckedselfCheckInCheckBoxChanged(val: boolean) {
    // when pass updated value of checkbox from parent component and update in local
    this.isCheckedselfCheckInCheckBox = val;
  }
  @Watch('eventAccessLinkQRCodeUrl')
  private oneventAccessLinkQRCodeUrlChanged(val: string) {
    // when pass updated value of event access link from parent component and update in local
    if(val !== '' && val !== undefined) {
      // if value is available then assign into local var and show saving the setting warning message
      this.localEventAccessLinkQRCodeUrl = val;
      this.showWarningMessageBar = false;
    } else {
      this.showWarningMessageBar = true;
    }
  }
}
