
















import { Component, Vue } from 'vue-property-decorator';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { StoreHelper } from '@/store/StoreHelper';
// Component
import CreateNewEvent from '@/components/EventSetting/EventDetail.vue';
import EventSettingTabs from '@/components/EventSetting/EventSettingTabs/EventSettingTabs.vue';

@Component({
  components: {
    CreateNewEvent,
    EventSettingTabs,
  },
})
export default class EventSetting extends Vue {
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();

  private mounted() {
    this.$root.$on('reload-eventsettingtab', () => {
      this.reqInfo = this.storeHelper.GetRequestInfoFromStore();
    });
  }
  private ShowEventSettingsPage() {
    if(this.reqInfo.ApplicationId !== 0) {
      return true;
    } else {
      return false;
    }
  }
  private showCreateNew() {
    // select create new event or edit event informtion
    if (this.reqInfo.ApplicationInstanceId > 0) {
      return false;
    } else {
      return true;
    }
  }
}
